import { defineStore } from "pinia";

export const useErrorStore = defineStore("errors", {
  state: () => ({
    errorLoading: false,
    errorLoadingCallBack: undefined
  }),
  actions: {
    setErrorLoading(callback) {
      this.errorLoadingCallBack = callback
      this.errorLoading = true;
    },
    callErrorLoadingCallBack() {
      this.errorLoadingCallBack.call().then((response) => {
        this.errorLoading = false;
        this.errorLoadingCallBack = undefined;
        return response;
      }, () => {

      }).finally(() => {
        
      });
    }
  }
});